<template>
  <PageListLayout
    icon="mdi-map-marker-outline"
    title="DANH SÁCH KHU VỰC"
    subTitle="Danh sách khu vực quản lý"
    useAdd
    titleAdd="Tạo mới khu vực"
    @add="createItem"
  >
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="formSearch.search"
          label="Tìm kiếm theo tên khu vực"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>

      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.province_id"
          :items="listProvince"
          label="Tỉnh"
          item-text="name"
          item-value="id"
          outlined
          dense
          clearable
          hide-details
          @change="onChangeProvince($event)"
          @click:clear="onChangeProvince($event)"
        ></v-autocomplete>
      </div>
      <div class="mt-4">
        <v-autocomplete
          v-model="formSearch.district_id"
          :items="listDistrict"
          label="Huyện"
          item-text="name"
          item-value="id"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </div>
    </template>

    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.order`]="{ item }">
        <span
          >{{ item.parent ? item.parent.order + "-" + item.order : item.order }}
        </span>
      </template>
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon>{{ item.icon }} </v-icon>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="isDeleteable" @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa Khu Vực</v-list-item-title
              >
            </v-list-item>
            <!-- <v-list-item @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa Khu Vực</v-list-item-title
              >
            </v-list-item> -->
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <create-edit ref="form" @on-done="getAllList"></create-edit>
  </PageListLayout>
</template>
<script>
import { getAreas, deleteAreas } from "@/api/khuvuc";
import * as DISTRICT from "@/api/district.api";
import * as PROVINCE from "@/api/province.api";
import CreateEdit from "./create-edit";
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: { CreateEdit },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      btnLoading: false,
      menu: {},
      loading: false,
      listDistrict: [],
      listProvince: [],
      formSearch: {
        search: "",
        province_id: null,
        district_id: null,
      },
      headers: [
        { text: "Tên khu vực", value: "name" },
        {
          text: "Huyện",
          align: "start",
          sortable: false,
          value: "district.name",
        },
        {
          text: "Tỉnh",
          align: "start",
          sortable: false,
          value: "province.name",
        },

        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "User/getMe",
    }),
    isDeleteable(){
      return ['sysadmin', 'admin'].includes(this.currentUser?.role?.code)
    }
  },
  created() {
    this.getAllList();
    this.getAllProvince();
    this.getAllDistrict();
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function(val) {
        this.getAllList();
      }, 300),
    },
  },
  methods: {
    onChangeProvince(event) {
      if (!event) {
        this.listDistrict = [];
        return;
      }

      this.formSearch.province_id = event;
      this.formSearch.district_id = null;
      this.getAllDistrict(event);
    },
    onChangeDistrict(event) {
      this.formSearch.district_id = event;
    },
    async getAllProvince() {
      let res = await PROVINCE.getAllProvince({
        is_paginate: false,
      });

      this.listProvince = res.data;
    },
    async getAllDistrict(province_id) {
      if (!province_id) this.listDistrict = [];
      let res = await DISTRICT.getAllDistrict({
        is_paginate: false,
        province_id: province_id,
      });
      this.listDistrict = res.data;
    },
    async getAllList() {
      this.loading = true;
      let data = await getAreas({
        page: this.page,
        perPage: this.itemsPerPage,
        is_paginate: true,
        with: ["district", "province"],
        ...this.formSearch,
      });
      console.log("data: ", data);
      this.loading = false;

      this.tableData = data.data.data;
      this.pageCount = data.data.last_page;
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa Khu Vực",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa khu vực " +
          "<strong>" +
          item.name +
          " ?" +
          "</strong>",
        action: () => deleteAreas(item.id ),
        onDone: this.getAllList,
      });
    },
    createItem() {
      this.$refs.form.showFormAdd();
    },
  },
};
</script>
