<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT KHU VỰC" : "THÊM KHU VỰC"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <div class="label-form">Tên khu vực</div>
              <v-text-field
                v-model="form.name"
                placeholder="Tên khu vực"
                outlined
                dense
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="label-form">Tỉnh</div>
              <v-autocomplete
                v-model="form.province_id"
                outlined
                placeholder="Tỉnh"
                :items="listProvince"
                item-value="id"
                item-text="name"
                dense
                @change="onChangeProvince($event)"
                :rules="provinceRules"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <div class="label-form">Huyện</div>
              <v-autocomplete
                v-model="form.district_id"
                outlined
                placeholder="Huyện"
                :items="listDistrict ? listDistrict : `Không có dữ liệu `"
                item-value="id"
                item-text="name"
                dense
                :disabled="form.province_id == null"
                @change="onChangeDistrict($event)"
                :rules="districtRules"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelFrom" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { addAreas, editAreas, getAllDistrictDepend } from "@/api/khuvuc";
import * as DISTRICT from "@/api/district.api";
import * as PROVINCE from "@/api/province.api";

export default {
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    listDistrict: [],
    listProvince: [],
    form: {
      name: null,
      district_id: null,
      province_id: null,
    },
    nameRules: [(v) => !!v || "Tên khu vực không thể bỏ trống"],
    districtRules: [(v) => !!v || "Huyện khu vực không thể bỏ trống"],
    provinceRules: [(v) => !!v || "Tỉnh khu vực không thể bỏ trống"],
  }),
  computed: {},
  mounted() {
    this.getAllProvince();
  },
  methods: {
    async getAllProvince() {
      let res = await PROVINCE.getAllProvince({
        is_paginate: false,
      });

      this.listProvince = res.data;
    },
    async getAllDistrict() {
      let res = await DISTRICT.getAllDistrict({
        is_paginate: false,
      });
      this.listDistrict = res.data;
    },
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = {
        name: null,
        district_id: null,
        province_id: null,
      };
    },
    cancelFrom() {
      this.show = false;
      this.form = {
        name: null,
        district_id: null,
        province_id: null,
      };
    },
    async onGetAllDistrictDepend(province_id) {
      let res = await getAllDistrictDepend(province_id);
      this.listDistrict = res;
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...data };
      this.onGetAllDistrictDepend(this.form.province_id);
    },
    async onChangeProvince(event) {
      this.province_id = event;
      this.onGetAllDistrictDepend(this.province_id);
    },
    onChangeDistrict(event) {
      this.district_id = event;
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await addAreas(this.form);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Thêm khu vực thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.$toast.error("lỗi tên khu vực đã tồn tại", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            message: error,
          });
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await editAreas(this.form.id, this.form);
          this.show = false;
          this.btnLoading = false;
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
