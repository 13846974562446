var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageListLayout',{attrs:{"icon":"mdi-map-marker-outline","title":"DANH SÁCH KHU VỰC","subTitle":"Danh sách khu vực quản lý","useAdd":"","titleAdd":"Tạo mới khu vực"},on:{"add":_vm.createItem}},[_c('template',{slot:"filter"},[_c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm theo tên khu vực","clearable":"","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.formSearch.search),callback:function ($$v) {_vm.$set(_vm.formSearch, "search", $$v)},expression:"formSearch.search"}})],1),_c('div',{staticClass:"mt-4"},[_c('v-autocomplete',{attrs:{"items":_vm.listProvince,"label":"Tỉnh","item-text":"name","item-value":"id","outlined":"","dense":"","clearable":"","hide-details":""},on:{"change":function($event){return _vm.onChangeProvince($event)},"click:clear":function($event){return _vm.onChangeProvince($event)}},model:{value:(_vm.formSearch.province_id),callback:function ($$v) {_vm.$set(_vm.formSearch, "province_id", $$v)},expression:"formSearch.province_id"}})],1),_c('div',{staticClass:"mt-4"},[_c('v-autocomplete',{attrs:{"items":_vm.listDistrict,"label":"Huyện","item-text":"name","item-value":"id","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.formSearch.district_id),callback:function ($$v) {_vm.$set(_vm.formSearch, "district_id", $$v)},expression:"formSearch.district_id"}})],1)]),_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ...","pageCount":_vm.pageCount,"classPaging":"pt-2"},on:{"update:page":function($event){_vm.page=$event},"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.parent ? item.parent.order + "-" + item.order : item.order)+" ")])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Cập nhật")],1)],1),(_vm.isDeleteable)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa Khu Vực")],1)],1):_vm._e()],1)],1)]}}],null,true)}),_c('create-edit',{ref:"form",on:{"on-done":_vm.getAllList}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }